import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"our-services"} />
		<Helmet>
			<title>
				Szolgáltatásaink | Szolgáltatásaink a Justitia Jogtanácsosoknál
			</title>
			<meta name={"description"} content={"A Justitia Ügyvédi Tanácsadó Kft: Személyre szabott jogi megoldások minden igényre\n"} />
			<meta property={"og:title"} content={"Szolgáltatásaink | Szolgáltatásaink a Justitia Jogtanácsosoknál"} />
			<meta property={"og:description"} content={"A Justitia Ügyvédi Tanácsadó Kft: Személyre szabott jogi megoldások minden igényre\n"} />
			<meta property={"og:image"} content={"https://konasbaltined.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://konasbaltined.com/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://konasbaltined.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://konasbaltined.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://konasbaltined.com/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://konasbaltined.com/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://konasbaltined.com/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Szolgáltatásaink
				</Text>
				<Text font="--lead" color="--darkL2" max-width="600px">
					A Justitia Jogi Tanácsadók átfogó jogi szolgáltatásokat nyújtanak, amelyek célja, hogy megfeleljenek ügyfeleink különböző igényeinek. Gyakorlati területeinken elkötelezett szakemberek dolgoznak, akik szakterületükre specializálódtak, így biztosítva a szakértői tanácsadást és képviseletet.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://konasbaltined.com/img/5.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Főbb szolgáltatási területeink
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							- Társasági jog: Csapatunk útmutatást nyújt a cégalapítással, szerződésekkel, fúziókkal és felvásárlásokkal kapcsolatban, biztosítva, hogy vállalkozása jogilag megalapozott legyen.
							<br />
							<br />
							- Családjog: Empátiával és professzionalizmussal kezeljük az olyan érzékeny ügyeket, mint a válás, a gyermekelhelyezés és a vagyonrendezés, megvédve az Ön és szerettei érdekeit.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://konasbaltined.com/img/6.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							- Büntetőjogi védelem: A tapasztalt védőügyvédek elkötelezettek a büntetőjogi vádakkal szembesülő személyek jogainak védelme mellett, és a jogi eljárás minden szakaszában erőteljes képviseletet biztosítanak.
							<br />
							<br />
							- Tulajdonjog és ingatlanjog: Csapatunk eligazodik az ingatlanjog rejtelmeiben, hogy biztosítsa befektetéseit, legyen szó akár lakásvásárlásról, akár összetett kereskedelmi ingatlanügyletről.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://konasbaltined.com/img/7.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							- Munkajog: Segítünk mind a munkáltatóknak, mind a munkavállalóknak a munkaszerződések, a munkahelyi viták és a munkajogi szabályok betartásának bonyolult kérdéseiben.
							<br />
							<br />
							- Szellemi tulajdon: A szabadalmi bejelentésektől a szerzői jogi vitákig mindent kezelünk.
						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Headline-4">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					width="50%"
					font="normal 500 52px/1.2 --fontFamily-serifTimes"
					padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					md-padding="0px 0 0px 0px"
					md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
					sm-margin="0px 0px 35px 0px"
				>
					Csatlakozzon hozzánk
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" color="#727a82" sm-margin="0px 0px 25px 0px">
						Ha részletesebb tájékoztatást szeretne kapni szolgáltatásainkról, vagy ha konkrét jogi igényeit szeretné megbeszélni, kérjük, forduljon hozzánk bizalommal. Csapatunk készen áll arra, hogy szakértői útmutatást és támogatást nyújtson Önnek.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});